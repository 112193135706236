.clientsTestimonials {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    background-color: #041A53;
    padding: 5%;
    color: #FFF;
}

.testimonials {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.clientsTestimonials>h2 {
    margin: 15px auto 25px;
    font-size: var(--main-header-font-size);
}

.statics {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    width: 30%;
    margin-top: 50px;
}

.statics div {
    width: 50%;
}


.statics div span {
    display: block;
}

.statics div span:first-of-type {
    font-size: 32px;
    margin-bottom: 3px;
    color: #fbe086;
}

@media (max-width:800px) {

    .clientsTestimonials {
        text-align: center;
    }

    .testimonials {
        flex-direction: column;
    }

    .statics {
        width: 100%;
    }

    .statics div {
        margin: 25px 0;
    }
}