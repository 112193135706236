.whatWeOffer {
    background-color: #16151A;
    color: #FFF;
    padding: 5%;
    text-align: center;
}

.whatWeOffer h2,
.whatWeOffer h3 {
    color: #F6BC1C;
    text-transform: capitalize;
    margin-bottom: 20px;
}

.whatWeOffer h2 {
    font-size: var(--main-header-font-size);
}

.whatWeOffer>hr {
    margin: 0 auto;
    width: 3%;
    border-style: dotted none none;
    border-width: 5px;
    border-color: #aaa;
}

.whatWeOffer h3 {
    text-transform: uppercase;
    font-size: 22px;
}

.whatWeOffer>.offering {
    display: flex;
    justify-content: space-between;
    margin: 50px 0;
}

.whatWeOffer>.offering div {
    width: 30%;
    text-align: start;
}

.whatWeOffer .offering .icon {
    color: #F6BC1C;
    font-size: 60px;
    margin-bottom: 20px;
}

.whatWeOffer .offering p {
    margin-bottom: 8px;
}

@media (max-width:720px) {
    .whatWeOffer>.offering {
        display: flex;
        flex-direction: column;
    }

    .whatWeOffer>.offering div {
        width: 100%;
        text-align: center;
        margin: 0 0 60px;
    }
}