.petroServices {
    color: var(--main-color);
    padding: 5%;
}

.petroServices h1 {
    font-size: 35px;
    text-align: center;
    margin: 0 auto 5%;
    width: 40%;
}

.petroServices .servicesHeading::before {
    top: -25px;
    left: -25px;
}

@media (max-width:920px) {
    .servicesHeading {
        font-size: 30px;
        margin: 20px auto;
        width: 80%;
        text-align: center;
    }

    .petroServices h1 {
        width: 100%;
    }

    .petroServices .servicesHeading::before {
        top: -15px;
        left: 5px;
    }
}