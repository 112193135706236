.scroll-button {
    z-index: 5;
    background-color: transparent;
    border: 0;
    outline: 0;
    color: var(--orange);
    font-size: 40px;
    position: fixed;
    cursor: pointer;
    bottom: 20px;
    right: 20px;
    transition: all .5s ease-in-out;
}

.scroll-button:hover {
    transform: translateY(-5px);
}