.title {
    margin: 0;
    position: absolute;
    top: 40%;
    left: 20%;
    transform: translate(-50%, -50%);
    width: 30%;
    border: 0;
    padding: 15px;
    color: #fff;
    border-block-start: 10px solid var(--yellow);
    border-inline-start: 10px solid var(--yellow);
    font-size: 42px;
    overflow: hidden;
    animation: outer-left 1s 1s ease both;
    z-index: 5;
    text-transform: capitalize;
    background-color: #00000025;
    border-end-end-radius: 25px;
}

.title.ar {
    animation: outer-right 1s 1s ease both;
}

.title legend {
    text-transform: capitalize;
    color: #FFF;
    padding: 0 15px;
    margin: 0 15px;
    font-size: 20px;
    font-weight: 900;
}

.inner {
    animation: text-clip 1s 0s cubic-bezier(0.5, 0, 0.1, 1) both;
}

@media (max-width: 900px) {
    .title {
        font-size: 23px;
        font-weight: 700;
    }
}

@keyframes text-clip {
    from {
        clip-path: polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%);
    }

    to {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    }
}

@keyframes outer-left {
    from {
        transform: translateX(50%);
    }

    to {
        transform: translateX(-50%);
    }
}

@keyframes outer-right {
    from {
        transform: translateX(50%);
    }

    to {
        transform: translateX(150%);
    }
}

@media (max-width:720px) {

    @keyframes outer-left {
        from {
            transform: translateX(25%);
        }

        to {
            transform: translateX(-25%);
        }
    }

    @keyframes outer-right {
        from {
            transform: translateX(-25%);
        }

        to {
            transform: translateX(0%);
        }
    }
}

@media (max-width:970px) {
    .title {
        width: 70%;
    }

    @keyframes outer-left {
        from {
            transform: translateX(-5%);
        }

        to {
            transform: translateX(-20%);
        }
    }
}