.serviceBox {
    padding: 10px 30px 0px;
    width: 100%;
}

.serviceBox img {
    min-width: 300px;
    width: 40%;
    height: 310px;
}

.serviceBox .serviceCard {
    color: #000;
}

.serviceBox .serviceCard>div {
    cursor: pointer;
    background-color: #fff;
    transition: all .2s ease-out;
    position: relative;
    bottom: 107px;
    left: 80px;
    width: 80%;
    padding: 20px 96px 20px 32px;
    border-inline-end: 5px solid var(--orange);
    box-shadow:
        0 2.8px 2.2px rgba(0, 0, 0, 0.034),
        0 6.7px 5.3px rgba(0, 0, 0, 0.048),
        0 12.5px 10px rgba(0, 0, 0, 0.06),
        0 22.3px 17.9px rgba(0, 0, 0, 0.072),
        0 41.8px 33.4px rgba(0, 0, 0, 0.086),
        0 100px 80px rgba(0, 0, 0, 0.12);
}

.serviceBox .serviceCard>div:hover {
    background-color: #EEE;
}

.serviceBox .serviceCard .exploreButton {
    color: var(--orange);
    font-size: 25px;
}

.serviceBox .serviceCard:hover .exploreButton {
    text-decoration: underline;
}

.serviceBox .serviceCard h2 {
    font-size: 36px;
    font-weight: 900;
    line-height: 36px;
    color: var(--main-color);
}

.serviceBox .serviceCard p {
    font-size: 18px;
    line-height: 1.2;
    margin: 18px 0;
}

@media (max-width:720px) {
    .serviceBox .serviceCard>div {
        text-align: center;
    }

    .serviceBox .serviceCard h2 {
        font-size: 25px;
    }
}

@media (max-width:970px) {
    .serviceBox img {
        width: 100%;
    }

    .serviceBox .serviceCard>div {
        width: 90%;
        left: 5%;
        padding: 20px;
    }
}