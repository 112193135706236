.card-info-wrapper {
    width: 35rem;
    border-bottom: 1px solid #dee4f1;
    margin: 0 auto;
    padding: 1rem;
    transition: 1s;
}

.card-info {
    display: flex;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 500;
    color: #212943;
    display: flex;
    justify-content: space-between;
}

.card-info svg {
    width: 1rem;
    height: 1.5rem;
    fill: #9ea6ae;
}

.card-info svg.active {
    transform: rotate(180deg);
}

.card-info svg:hover {
    opacity: 0.8;
}

button {
    background-color: transparent;
    border: none;
    cursor: pointer;
}

button:focus {
    outline: none;
}

.info {
    display: none;
}

.info.active {
    display: block;
    text-align: start;
    padding-top: 1.5rem;
    font-weight: light;
    line-height: 1.5;
    color: #9ea6ae;
    height: 0%;
    animation: slidein 0.7s;
    animation-fill-mode: both;
    height: 170px;
}

@keyframes slidein {
    from {
        opacity: 0.3;
        transform: translateY(-20%);
    }

    to {
        opacity: 1;
        transform: translateY(0%);
    }
}

@keyframes slideout {
    from {
        opacity: 1;
        transform: translateY(0%);
    }

    to {

        opacity: 0.3;
        transform: translateY(-20%);
    }
}

@media (max-width:980px) {
    .card-info-wrapper {
        width: 100%;
    }
}