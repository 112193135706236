.renting {
    text-align: start;
    margin: 50px 0;
}

.renting .equoipmentTitle {
    font-size: 42px;
    line-height: 53px;
    color: #979FAD;
}

.renting .equoipmentTitle:hover {
    text-decoration: underline;
}

.renting .equoipmentTitle::before {
    left: -25px;
    top: -25px;
}

.renting>p {
    padding: 1rem 0;
    font-size: 18px;
    letter-spacing: .2px;
    line-height: 32px;
}

.rentingCards {
    display: flex;
    flex-wrap: wrap;
    gap: 25px;
    flex-direction: row;
}

.rentingCards .card {
    width: 30%;
}

.seeMore {
    cursor: pointer;
    font-size: 25px;
    margin: 20px auto;
    position: relative;
    left: 50%;
    transition: all .5s ease-in-out;
}

.seeMore.down:hover {
    transform: translateY(5px);
}

.seeMore.up:hover {
    transform: translateY(-5px);
}

@media (max-width:870px) {

    .rentingCards {
        flex-direction: column;
        gap: 0px;
    }

    .rentingCards .card {
        margin-bottom: 20px;
        width: 100%;
    }

    .renting>h2 {
        font-size: 32px;
    }
}