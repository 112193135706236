.projectInfo {
    display: flex;
    padding: 5%;
    justify-content: space-around;
    position: relative;
}

.projectImageWrapper {
    position: relative;
    width: 470px;
    box-shadow:
        rgba(158, 129, 48, 0.4) -5px -5px,
        rgba(158, 129, 48, 0.3) -10px -10px,
        rgba(158, 129, 48, 0.2) -15px -15px,
        rgba(158, 129, 48, 0.1) -20px -20px,
        rgba(158, 129, 48, 0.05) -25px -25px;
}

.projectImageWrapper>img {
    width: 100%;
    height: 560px;
}

.aboutProject {
    width: 50%;
    background-color: var(--main-color);
    padding: 5%;
    color: #FFF;
    height: 100%;
}

.aboutProject>h1 {
    font-size: 42px;
    margin-bottom: 50px;
    color: var(--yellow);
}

.aboutProject>p {
    font-size: 1.4rem;
    line-height: 1.4;
    letter-spacing: -0.01em;
}

.projectDescription {
    margin-top: 100px;
    background-color: #16151A;
    padding: 5% 15%;
}

.projectDescription .projectHighlight {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 50px 0;
}

.projectDescription>div:nth-of-type(odd) .projectHighlight {
    flex-direction: row-reverse;
}

.projectDescription .projectHighlight ul>li {
    color: #FFF;
    margin: 10px 0 20px;
}

.projectDescription .projectHighlight>div {
    width: 40%;
}

.projectDescription .projectHighlight>div h2 {
    font-size: 2.625rem;
    margin-bottom: 30px;
    line-height: 52px;
    color: var(--yellow);
}

.projectDescription .projectHighlight>div p {
    color: #d0d0d0;
    font-weight: 400;
    font-size: 1rem;
    line-height: 26px;
}

.projectDescription .projectHighlight>img {
    width: 55%;
    border-radius: 25px;
    height: 300px;
}

.testmonialContainer {
    color: #FFF;
    font-size: 15px;
    line-height: 1.6em;
    text-align: center;
    background: #eee;
}

.testimonial {
    max-width: 600px;
    margin: 0 auto;
    padding: 10vh 0;
}

.testimonial blockquote {
    margin: 10px 10px 0;
    background: var(--main-color);
    padding: 20px 60px;
    position: relative;
    border: none;
    border-radius: 8px;
    font-style: italic;
    font-size: 20px;
}

.testimonial blockquote:before,
.testimonial blockquote:after {
    content: "\201C";
    position: absolute;
    font-size: 80px;
    line-height: 1;
    color: #FFF;
    font-style: normal;
}

.testimonial blockquote:before {
    top: 10px;
    left: 10px;
}

.testimonial blockquote:after {
    content: "\201D";
    right: 10px;
    bottom: -0.5em;
}

.testimonial div {
    width: 0;
    height: 0;
    border-left: 0 solid transparent;
    border-right: 20px solid transparent;
    border-top: 20px solid var(--main-color);
    margin: 0 0 0 60px;
}

.testimonial p {
    margin: 8px 0 0 20px;
    text-align: left;
    font-size: 18px;
    font-weight: 500;
    color: var(--main-color);
}

@media (max-width:980px) {
    .projectInfo {
        padding: 5%;
        justify-content: space-around;
        flex-direction: column-reverse;
    }

    .aboutProject {
        width: 100%;
        z-index: 11;
        left: -10px;
        bottom: 15px;
    }

    .projectImageWrapper {
        position: relative;
        width: 100%;
        margin-top: 25px;
    }

    .projectImageWrapper>img {
        position: relative !important;
    }

    .projectDescription .projectHighlight,
    .projectDescription>div:nth-of-type(odd) .projectHighlight {
        flex-direction: column-reverse;
        margin: 0;
        padding: 20px 0;
    }

    .projectDescription .projectHighlight>div,
    .projectDescription .projectHighlight>img {
        width: 100%;
    }

    .projectDescription .projectHighlight>div {
        margin: 45px 0 20px;
        text-align: center;
    }

    .projectDescription .projectHighlight>div h2 {
        font-size: 1.625rem;
        line-height: 22px;
    }

    .testimonial {
        margin: 0 15px;
    }
}