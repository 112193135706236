.youtube {
    aspect-ratio: 16/9;
    position: relative;
    width: 100%;
    height: 100%;
}

.youtubeFrame {
    width: 100%;
    height: 100%;
}

.playButton,
.placeholderImage {
    width: 100%;
    height: 100%;
}

.playButton {
    position: relative;
    outline: none;
    border: none;
    cursor: pointer;
}

.playIcon {
    display: block;
    width: 104px;
    height: 104px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -52px 0 0 -52px;
    cursor: pointer;
    z-index: 5;
    border-radius: 50%;
    background-color: #ff381e46
}

.videoPlayOutline {
    display: block;
    width: 104px;
    height: 104px;
    border: #FFF 4px solid;
    border-radius: 50%;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    opacity: .7;
    transition: all .5s;
}

.videoPlayCircle {
    stroke-dasharray: 315;
    stroke-dashoffset: 315;
    transition: stroke-dashoffset .5s;
}

.playButton:hover .videoPlayCircle {
    stroke-dashoffset: 0;
}

.playIcon:active .videoPlayOutline {
    transform: scale(1.5);
    opacity: 0;
}

.playIcon svg {
    display: block;
    transform: rotate(-90deg);
    transition: transform .3s;
}

.playButton:hover .playIcon svg path {
    fill: #FFF;
}

.playIcon svg path {
    stroke: white;
    transition: all .5s ease-in-out;
}

.playIcon:active svg {
    transform: rotate(-90deg) scale(.95);
}

.placeholderImage {
    width: 100%;
}

.caption {
    color: #fff;
    position: absolute;
    bottom: 10px;
    font-size: 25px;
    font-weight: 900;
}

@keyframes pulse {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }

    100% {
        -webkit-transform: scale(1.75);
        transform: scale(1.75);
        opacity: 0;
    }
}