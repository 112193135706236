.contectUs {
    padding: 0 5%;
}

.contactInfo {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 5% 2%;
}

.contactInfo .vertical {
    border-left: 1px solid #A4A8B1;
}

.contactInfo>div {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.contactInfo>div .contactIcon {
    font-size: 25px;
    padding: 15px;
    background-color: #CCDDFF;
    border-radius: 50%;
    margin-inline-end: 15px;
}

.contactInfo>div>div h4 {
    font-weight: 900;
    margin-bottom: 5px;
}

.contactInfo>div>div p {
    color: #A4A8B1;
}

.contactInfo div p {
    display: inline-block;
    width: 100%;
}

.contactForm {
    margin: 2.5% 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.contactForm iframe {
    box-shadow: rgba(36, 55, 99, 0.4) 5px -5px, rgba(36, 55, 99, 0.3) 10px -10px, rgba(36, 55, 99, 0.2) 15px -15px, rgba(36, 55, 99, 0.1) 20px -20px, rgba(36, 55, 99, 0.05) 25px -25px;
}

@media (max-width:980px) {
    .contactInfo {
        flex-direction: column;
    }

    .contactInfo>div {
        margin: 8px 0;
    }

    .contactForm {
        flex-direction: column;
        width: 100%;
    }

    .contactForm iframe {
        width: 100%;
        margin: 35px 0;
    }
}