.aboutUs {
    display: flex;
    align-items: center;
    flex-direction: row;
}

.aboutUsAr {
    flex-direction: row-reverse;
}

.aboutUsDetails {
    width: 60%;
    padding: 5%;
}

.aboutUsDetails>span {
    background-color: var(--yellow);
    color: var(--main-color);
    padding: 10px 25px;
    font-weight: 900;
    border-radius: 5px;
    text-transform: uppercase;
    font-size: var(--main-side-header-font-size);
}

.aboutUsDetails>h3 {
    margin: 50px 0 0;
    font-weight: 500;
    font-size: 30px;
    color: #142A39;
}

.aboutUsDetails>h3>span {
    font-weight: 900;
}

.aboutUsDetails>p {
    color: #000;
    margin: 35px 0;
    width: 100%;
    font-size: 19px;
    line-height: 1.3;
}

.aboutUsDetails>div {
    display: flex;
    flex-wrap: wrap;
}

.aboutUsDetails>div>span {
    width: 50%;
    margin: 25px 0;
    font-size: 25px;
    text-transform: capitalize;
    color: #142A39;
}

.aboutUsDetails>div>span .aboutUsIcon {
    font-size: 25px;
    margin-right: 10px;
}

.pics {
    width: 40%;
    display: flex;
    flex-wrap: wrap;
    padding: 20px;
}

.pics img {
    margin: 15px;
    width: 35%;
    height: 215px;
    border-radius: 10px;
}

.pics img:nth-of-type(2) {
    margin-top: 50px;
}

.pics img:nth-of-type(3) {
    margin-top: -25px;
}

@media (max-width:930px) {

    .aboutUsDetails>p {
        font-size: 19px;
    }

    .aboutUsDetails>div>span {
        width: 100%;
        font-size: 19px;
        font-weight: bold;
    }

    .pics {
        width: 50%;
        flex-direction: column;
        padding: 30px;
    }

    .pics img {
        margin: 15px 0;
        width: 100%;
        height: 150px;
        border-radius: 10px;
    }

    .pics img:nth-of-type(2) {
        margin-top: 0px;
    }

    .pics img:nth-of-type(3) {
        margin-top: 0px;
    }
}