.serviceInfo {
    padding: 5% 3% 15%;
    color: var(--main-color);
    display: flex;
    justify-content: space-between;
    background-color: #ebebeb;
}

.serviceInfo .infoMedia {
    width: 50%;
}

.serviceInfo .serviceMainImage {
    width: 100%;
    height: 500px;
}

.serviceInfo .aboutService {
    width: 40%;
    font-size: 18px;
}

.serviceInfo .aboutService>h1 {
    text-transform: capitalize;
    color: var(--yellow);
    margin-bottom: 25px;
}

.serviceInfo .aboutService>p {
    margin-bottom: 15px;
}

.serviceInfo .aboutService h4 {
    line-height: 3;
    font-size: 20px;
    text-decoration: underline;
}

.serviceImages {
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: relative;
    top: -100px;
    width: 80%;
    margin: 0 auto;
}

.serviceImages img {
    width: 250px;
    height: 200px;
}

.featuresTitle {
    text-align: center;
    font-size: 50px;
    margin: 0 auto;
    padding: 40px;
    color: var(--yellow);
    background-color: #16151A;
}

.serviceFeatures {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    padding: 5%;
    justify-content: space-around;
}

.featureWrapper {
    position: relative;
    border-radius: 3rem;
    background: #16151A;
    height: 600px;
    display: flex;
    flex-flow: column nowrap;
    transition: all 0.3s;
    width: 30%;
    margin: 20px 0;
    color: var(--yellow);
}

.featureWrapper:hover {
    box-shadow: 0 0 40px 0 #16151A;
    transform: scale(1.02);
}

.imageWrapper {
    padding: 3rem;
    width: 100%;
    min-height: 400px;
}

.imageWrapper img {
    width: 100%;
    height: 100%;
    border-radius: 3rem;
}

.featureWrapper h2 {
    text-align: center;
    font-size: 1.7rem;
    line-height: 1.5;
    margin: 20px auto 50px;
    width: 90%;
    cursor: initial;
}

.serviceFeatures .feature {
    text-align: center;
    background-size: cover;
    align-items: center;
    height: 250px;
    width: 100%;
    transition: all .5s ease-in-out;
    position: relative;
}

.serviceFeatures .feature:first-of-type {
    grid-row-start: 1;
    grid-row-end: 4;
    height: 100%;
}

.serviceFeatures .feature img {
    width: 100%;
    height: 100%;
}

.serviceFeatures .feature h2 {
    font-family: 'Montagu Slab', serif;
    font-size: 2.6875rem;
    font-weight: 400;
    padding: 15px;
    color: #FFF;
    position: absolute;
    text-align: center;
    width: 100%;
    height: 100%;
    top: 0%;
    opacity: 0;
    background-color: #041a5342;
    transition: all .5s ease-in-out;
}

.serviceFeatures .feature:hover h2 {
    opacity: 1;
    transform: translate(15px, 15px);
}

.projectsTitle {
    padding: 0 5% 5%;
    font-size: 35px;
    color: var(--main-color);
}

@media (max-width:980px) {

    .serviceInfo {
        flex-direction: column;
    }

    .serviceInfo .infoMedia {
        width: 100%;
    }

    .serviceInfo>img {
        width: 100%;
        margin-bottom: 25px;
    }

    .serviceInfo .aboutService {
        width: 100%;
        text-align: center;
        padding-top: 5%;
    }

    .serviceImages {
        flex-wrap: wrap;
        top: 0px;
        width: 100%;
    }

    .serviceImages img {
        width: 100%;
        height: 300px;
        margin-bottom: 20px;
    }

    .featureWrapper {
        width: 100%;
    }
}