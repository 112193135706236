.generalServicesHeader {
    display: flex;
    justify-content: space-between;
}

.generalServicesBody {
    width: 66.66%;
    padding: 106px 15%;
}

.generalServicesBody>h2 {
    font-size: 42px;
    line-height: 53px;
    position: relative;
    color: var(--main-color);
}

.generalServicesBody>h2::before {
    content: "";
    position: absolute;
    width: 11px;
    height: 11px;
    top: -12px;
    left: -18px;
    border-top: 5px solid var(--orange);
    border-left: 5px solid var(--orange);
    display: block;
}

.generalServicesBody>p {
    font-size: 18px;
    letter-spacing: .2px;
    line-height: 32px;
    color: #2D3A4D;
    padding-top: 25px;
}

.relatedResources {
    width: 33.33%;
    background-color: #E5E9ED;
    padding: 4rem 4rem 2.6875rem;
}

.relatedResources>h3 {
    text-decoration: underline;
    text-transform: capitalize;
}

.relatedResources>a {
    display: block;
    margin: 25px 0;
    font-size: 17px;
    font-weight: 900;
    line-height: 28px;
    color: #C00;
    transition: all .2s ease-out;
}

.relatedResources>a:hover {
    text-decoration: underline;
}

.generalServicesList {
    display: flex;
    background-color: #f3f3f3;
    flex-direction: row;
    min-height: 250px;
    overflow: hidden;
    justify-content: space-between;
}

.generalServicesList:nth-of-type(even) {
    flex-direction: row-reverse;
}

.generalService {
    width: 70%;
    text-align: center;
    padding: 5% 10%;
}

.serviceTitle {
    font-size: 2.4rem;
    margin: 40px 0;
    line-height: 1.3;
    color: var(--main-color);
}

.generalServicesList>div p {
    letter-spacing: .2px;
    font-size: 1.1rem;
    line-height: 2;
    color: #2D3A4D;
}

.generalServicesList>div a {
    position: relative;
    cursor: pointer;
    display: inline-block;
    width: fit-content;
    transition: all 0.3s;
    color: var(--orange);
    text-align: center;
    font-size: 1.5rem;
    line-height: 1.8;
    margin: 25px 0;
}

.generalServicesList>div a:after {
    content: '';
    position: absolute;
    height: 1.5px;
    width: 100%;
    background: currentColor;
    bottom: -6px;
    opacity: 0.2;
    left: 0;
}

.generalServicesList>div a:before {
    content: '';
    position: absolute;
    height: 1.5px;
    width: 0;
    transition: all 0.4s ease-in-out;
    background: currentColor;
    bottom: -6px;
    left: auto;
    right: 0;
}

.generalServicesList>div a:hover:before {
    width: 100%;
    right: auto;
    left: 0;
}

.serviceImage {
    width: 100%;
    height: 100%;
}

@media (max-width:1220px) {
    .generalService {
        width: 50%;
        text-align: center;
        padding: 5%;
    }
}

@media (max-width:720px) {
    .generalServicesHeader {
        flex-direction: column;
    }

    .generalServicesHeader .generalServicesBody {
        width: 100%;
        padding: 10%;
    }

    .generalServicesBody>h2 {
        font-size: 30px;
    }

    .generalServicesHeader .relatedResources {
        width: 100%;
        padding: 10%;
    }

    .generalServicesList,
    .generalServicesList:nth-of-type(even) {
        flex-direction: column;
    }

    .generalService {
        width: 100%;
    }
}