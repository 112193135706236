.backgroundImage {
    width: 100%;
    background-size: cover;
}

.imageContainer {
    background-size: cover;
    background-position: center;
    height: 100vh;
    background-image: url("/public/Assets/Images/headers/header-4.webp");
    background-color: var(--main-color);
    width: 100%;
    animation: backgrounds 15s linear infinite;
    overflow: hidden;
}

.headerTitle {
    position: absolute;
    top: 35%;
    left: 25%;
    width: 50%;
    padding: 15px 15px;
    color: #fff;
    border-left: 7px solid #243763;
    text-transform: uppercase;
    background-color: #2437637c;
    filter: blur(25);
    cursor: default;
    text-align: center;
    z-index: 1;
    overflow: hidden;
}

.headerTitle h1 {
    animation: moveInleft 5s infinite;
    font-size: 60px;
    font-weight: 800;
}

.headerTitle h2 {
    animation: moveInRight 5s infinite;
    font-size: 20px;
    font-weight: 700;
}

@media(max-width:900px) {
    .headerTitle h1 {
        font-size: 29px;
    }

    .headerTitle h2 {
        font-size: 14px;
    }
}

@media(max-width:550px) {
    .headerTitle {
        position: absolute;
        top: 35%;
        left: 5%;
        width: 90%;
        padding: 35px 15px;
        color: #fff;
        border-left: 7px solid #243763;
        padding-left: 15px;
        text-transform: uppercase;
        background-color: #2437637c;
        cursor: default;
        text-align: center;
        z-index: 1;
        font-size: 8px;
        overflow: hidden;
    }

    .headerTitle h1 {
        font-size: 40px;
        margin-bottom: 25px;
    }

    .headerTitle h2 {
        font-size: 25px;
    }
}

@keyframes moveInleft {
    0% {
        opacity: 0;
        transform: translateX(-100px);
    }

    18% {
        transform: translateX(10px);
    }

    30%,
    95% {
        opacity: 1;
        transform: translate(0);
    }

    100% {
        opacity: 0;
    }
}

@keyframes moveInRight {
    0% {
        opacity: 0;
        transform: translateX(100px);
    }

    18% {
        transform: translateX(-10px);
    }

    30%,
    95% {
        opacity: 1;
        transform: translate(0);
    }

    100% {
        opacity: 0;
    }
}

@keyframes changing {
    0% {
        opacity: 0;
        transform: scale(1);
    }

    45% {
        opacity: 0;
    }

    55% {
        opacity: 1;
    }

    95% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        transform: scale(1.1);
    }
}

@keyframes backgrounds {
    0% {
        background-image: url("/public/Assets/Images/headers/header-4.webp");
        transform: scale(1);
    }

    28% {
        background-image: url("/public/Assets/Images/headers/header-4.webp");
    }

    33% {
        background-image: url("/public/Assets/Images/headers/header-2.webp");
    }

    64% {
        background-image: url("/public/Assets/Images/headers/header-2.webp");
        transform: scale(1.1);
    }

    66% {
        background-image: url("/public/Assets/Images/headers/header-3.webp");
    }

    95% {
        background-image: url("/public/Assets/Images/headers/header-3.webp");
    }

    100% {
        background-image: url("/public/Assets/Images/headers/header-4.webp");
        transform: scale(1);
    }
}