.projectCard {
    display: flex;
    flex-direction: row;
}

.projectCard:nth-of-type(odd) {
    flex-direction: row-reverse;
}

.projectCardDetails,
.projectCard .image {
    width: 50%;
}

.projectCard .projectCardDetails {
    padding: 5%;
    background-color: #1A3A4E;
    color: #fff;
}

.projectCard .projectCardDetails>h2 {
    font-size: 42px;
    line-height: 53px;
    color: var(--yellow);
}

.projectCard .projectCardDetails>p {
    font-size: 18px;
    letter-spacing: .2px;
    line-height: 32px;
    margin: 20px 0 48px;
}

.projectCard .projectCardDetails>a {
    border-bottom: 3px solid var(--orange);
    background: #fff;
    color: var(--orange);
    padding: 13px 31px;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: .5px;
    text-transform: uppercase;
    transition: all .2s ease-out;
}

.projectCard .projectCardDetails>a:hover {
    background: var(--orange);
    color: #fff
}

.projectCard .image {
    min-height: 100%;
    background-size: cover;
    background-position: bottom;
}

.projectImg {
    width: 100%;
    height: 100%;
}

@media (max-width:980px) {

    .projectCard,
    .projectCard:nth-of-type(odd) {
        display: flex;
        flex-direction: column-reverse;
    }

    .projectCard .projectCardDetails {
        width: 100%;
        padding: 10%;
        background-color: var(--main-color);
        color: #fff;
    }

    .projectCard .projectCardDetails>h2 {
        font-size: 30px;
        width: 100%;
    }

    .projectCard .image {
        width: 100%;
        height: 300px;
    }
}