.contactForm {
    width: 50%;
}

.contactForm h2 {
    text-transform: capitalize;
}

.contactForm p {
    margin: 25px 0;
    width: 75%;
    color: #A4A8B1;
    line-height: 1.3;
}

.contactForm input,
.contactForm textarea {
    width: 70%;
    margin: 10px 0;
    padding: 10px;
    font-size: 17px;
    border-radius: 15px;
    border: 0;
    outline: none;
    background-color: #dedede;
}

.contactForm input[type="submit"] {
    color: #FFF;
    background-color: var(--orange);
    border: 1px solid var(--orange);
    cursor: pointer;
    width: 30%;
    margin: 0 20%;
    transition: all .2s ease-in-out;
    display: block;
}

.contactForm input[type="submit"]:hover {
    color: var(--orange);
    background-color: #FFF;
}

.contactForm textarea {
    height: 100px;
}

@media (max-width:980px) {

    .contactForm {
        width: 100%;
    }

    .contactForm div {
        width: 100%;
    }

    .contactForm p {
        width: 100%;
    }

    .contactForm input,
    .contactForm textarea {
        width: 95%;
    }

    .contactForm input[type="submit"] {
        color: #FFF;
        background-color: var(--orange);
        border: 1px solid var(--orange);
        cursor: pointer;
        width: 40%;
        margin: 0 auto;
        transition: all .2s ease-in-out;
        display: block;
    }
}