.notFound {
    height: 100vh;
    text-align: center;
    position: relative;
    margin: 0 auto;
    background-color: #000;
    color: #FFF;
}

.notFound>div {
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    max-width: 30%;
    text-align: start;
}

.notFound h4 {
    font-size: 28px;
    font-weight: 700;
    text-transform: uppercase;
    margin-top: 0;
}

.notFound p {
    font-size: 20px;
    font-weight: 400;
    margin-top: 0;
    margin-bottom: 15px;
    color: #ababab;
}