.footer {
    background-image: linear-gradient(to right, #01093A, #2D0B01);
    padding: 5%;
    color: #aaa;
    text-align: center;
    position: static;
}

.footerContent {
    display: flex;
    flex-direction: row;
}

.footerContent h2 {
    margin-bottom: 25px;
    text-transform: capitalize;
    color: #fede8c;
}

.footerContent>div {
    text-align: start;
}

.companyDetails {
    width: 30%;
}

.companyDetails p {
    width: 80%;
    line-height: 1.4;
}

.officeDetails {
    width: 25%;
}

.officeDetails span {
    display: block;
    margin: 10px 0;
}

.address {
    font-size: 22px;
    color: #FFF;
    font-weight: 500;
}

.officeDetails span:last-of-type,
.officeDetails span:nth-last-of-type(2) {
    margin-top: 15px;
}

.services {
    width: 20%;
}

.services .serviceLink {
    display: block;
    color: #FFF;
    margin-top: 10px;
    position: relative;
    text-decoration: none;
    line-height: 24px;
    width: 50%;
}

.serviceLink:before,
.serviceLink:after {
    content: '';
    position: absolute;
    transition: transform .5s ease;
}

.serviceLink:before {
    left: auto;
    bottom: 0;
    width: 50px;
    height: 2px;
    background: #FFF;
    transform: scaleX(0);
}

.serviceLink:hover:before {
    transform: scaleX(1);
}

.newsletter {
    width: 30%;
}

.newsletter form {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 70%;
}

.emailField {
    background: transparent;
    border: none;
    outline: none;
    color: #FFF;
    font-size: 18px;
    padding-bottom: 5px;
    outline: none;
    border-bottom: 1px solid #FFF;
}

.newsletter input[type="submit"] {
    background-color: #FFF;
    color: var(--main-color);
    font-weight: 900;
    font-size: 30px;
    width: 30px;
    height: 30px;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 50%;
    transition: all .5s ease-in-out;
}

.newsletter input[type="submit"]:hover {
    color: var(--orange);
    background-color: var(--main-color);
    transform: translateX(10px);
}

.submitBtn {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    color: #fede8c;
    font-size: 25px;
    transition: all .5s ease-in-out;
}

.submitBtn:hover {
    transform: translate(5px, -5px);
}

.socialLinks {
    margin-top: 25px;
}

.socialLinks a {
    margin: 5px 7px 5px 0;
}

.socialLinks a .icon {
    color: #000;
    padding: 8px;
    background-color: #FFF;
    border-radius: 50%;
    transition: all .2s ease-in-out;
}

.socialLinks a .facebook {
    color: #3b5998;
}

.socialLinks a .facebook:hover {
    background-color: #3b5998;
}

.socialLinks a .youtube {
    color: #cd201f;
}

.socialLinks a .youtube:hover {
    background-color: #cd201f;
}

.socialLinks a .linkedin {
    color: #0A66C2;
}

.socialLinks a .email {
    color: #00405d;
}

.socialLinks a .linkedin:hover {
    background-color: #0A66C2;
}

.socialLinks a .phone {
    color: #00405d;
}

.socialLinks a .phone:hover {
    background-color: #00405d;
}

.socialLinks a .whatsapp {
    color: #43d854;
}

.socialLinks a .whatsapp:hover {
    background-color: #43d854;
}

.socialLinks a .email:hover {
    background-color: #00405d;
}

.socialLinks a .facebook:hover,
.socialLinks a .youtube:hover,
.socialLinks a .linkedin:hover,
.socialLinks a .phone:hover,
.socialLinks a .whatsapp:hover,
.socialLinks a .email:hover {
    color: #FFF;
    transform: translateY(-2px);
}

.footer hr {
    margin: 25px;
}

@media (max-width:800px) {

    .footerContent {
        flex-direction: column;
    }

    .footerContent>div {
        width: 100%;
        margin-bottom: 10px;
    }

    .footerContent h2 {
        margin: 20px 25px;
        font-size: 32px;
    }

    .newsletter {
        align-content: center;
        text-align: center;
        margin: 0 auto;
    }

    .newsletter form {
        display: flex;
        align-items: center;
        justify-content: space-around;
        width: 70%;
        margin: 25px auto;
    }
}

@media (max-width:1080px) {

    .footerContent>div {
        text-align: center;
        width: 100%;
    }

    .footerContent>div h2 {
        margin-bottom: 15px;
    }

    .companyDetails p {
        width: 100%;
    }

    .officeDetails span {
        display: inline;
        margin: 0 4px;
    }

    .officeDetails .address {
        display: block;
        margin: 20px 0;
    }

    .officeDetails span:last-of-type,
    .officeDetails span:nth-last-of-type(2) {
        display: block;
    }

    .services {
        width: 100%;
    }

    .services .serviceLink {
        width: 100%;
    }
}

@keyframes moving {

    0% {
        transform: rotateZ(0deg);
    }

    33.3% {
        transform: rotateZ(10deg);
    }

    66.6% {
        transform: rotateZ(-10deg);
    }

    100% {
        transform: rotateZ(0deg);
    }
}