.navbar,
.links {
    display: flex;
    align-items: center;
}

.navbar {
    display: flex;
    justify-content: space-between;
    padding: 10px 50px;
    align-items: center;
    z-index: 20;
    position: fixed;
    width: 100%;
    transition: all .5s ease-in-out;
}

.logo {
    width: 20%;
    display: flex;
    align-items: center;
}

.logoImg {
    width: 70px;
}

.comapnyTitle {
    display: inline-block;
    font-size: 18px;
    font-weight: 900;
    letter-spacing: 0px;
    color: #fff;
    margin-inline-start: 15px;
}

.links {
    justify-content: flex-end;
    padding: 0 80px;
    font-size: 18px;
}

.links li,
.languageChanger {
    list-style: none;
    padding: 0 20px;
    position: relative;
    color: #EEE;
    text-transform: capitalize;
}

.link {
    font-size: 18px;
    font-weight: 600;
    transition: 0.3s ease;
}

.link:hover,
.link.activeLink {
    color: var(--orange);
}

.link:hover::after,
.activeLink::after {
    content: "";
    width: 30%;
    height: 2px;
    background: var(--orange);
    position: absolute;
    bottom: -6px;
    left: 20px;
}

.dropdown {
    position: relative;
    display: inline-block;
    cursor: pointer;
}

.dropdownContent {
    display: none;
    position: absolute;
    background-color: #041A53;
    min-width: 180px;
    z-index: 1;
    padding-top: 25px;
    left: 1px;
    box-shadow: 5px 5px 10px #000;
}

.dropdownContent a {
    padding: 18px 16px;
    display: block;
}

.dropdownContent a:hover {
    background-color: #ddd;
}

.dropdown:hover>.link {
    color: var(--orange);
}

.dropdown:hover .dropdownContent {
    display: block;
}

.close {
    visibility: hidden;
}

.mobileNav,
.close {
    display: none;
}

.mobileNav a,
.links li a {
    color: white;
}

.languageChanger {
    cursor: pointer;
}

.mobileNav a:hover,
.links li a:hover,
.languageChanger:hover {
    color: var(--orange);
}

@media(max-width:1080px) {
    .navbar {
        padding: 20px 15px;
        overflow: hidden;
    }

    .links {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        position: fixed;
        top: 0;
        right: calc(260px - 100vw);
        height: 100vh;
        width: 250px;
        background-color: #e3e6f3;
        box-shadow: 0 40px 60px rgba(0, 0, 0, .1);
        padding: 80px 0 0 10px;
        transition: .6s ease-in-out;
        z-index: 2;
    }

    .links.active {
        right: 0;
    }

    .links .close {
        position: absolute;
        top: 30px;
        left: 50px;
        color: #222;
        font-size: 24px;
        cursor: pointer;
        transition: .8s ease;
        display: block;
        visibility: visible;
    }

    .links .close:hover {
        transform: rotateZ(-180deg);
    }

    .links li {
        margin-bottom: 25px;
    }

    .links li a,
    .link {
        color: var(--main-color);
    }

    .links .languageChanger {
        display: none;
    }

    .activeLink,
    .dropdownContent>.link.activeLink,
    .dropdownContent>.link:hover {
        color: var(--orange);
    }

    .link:hover::after,
    .activeLink::after {
        bottom: 0px;
    }

    .dropdownContent>.link {
        color: #FFF;
    }

    .mobileNav {
        display: flex;
        align-items: center;
    }

    .mobileNav .menuButton {
        color: #EEE;
        padding-inline-start: 20px;
        cursor: pointer;
    }

    .mobileNav.logoImg {
        margin-bottom: 20px;
        width: 120px;
    }
}

@media(max-width:600px) {
    .navbar {
        padding: 10px px;
    }

    .links {
        right: -200vw;
        width: 100vw;
    }

    .dropdownContent {
        display: none;
        position: absolute;
        background-color: #243763;
        min-width: 180px;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        z-index: 1;
        padding: 0;
    }

    .mobileNav {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}