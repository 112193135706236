.equipmentCard {
    display: flex;
    flex-flow: column nowrap;
    height: 750px;
    color: #000;
    transition: all .2s ease-out;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, .2);
    position: relative;
}

.equipmentCard .equipmentImage {
    width: 100%;
    height: 250px;
    margin-bottom: 35px;
    position: unset;
}

.equipmentCard>span {
    font-size: 21px;
    letter-spacing: .91px;
    padding: 0 2rem 1rem;
    text-transform: uppercase;
}

.equipmentCard>span::before {
    top: -35px;
}

.equipmentCard>p {
    font-size: 18px;
    letter-spacing: .2px;
    line-height: 32px;
    padding: 0 2rem 2rem;
    color: #666666;
}

.equipmentCard>a {
    font-size: 17px;
    font-weight: 500;
    letter-spacing: .5px;
    color: var(--orange);
    text-transform: uppercase;
    text-decoration: none;
    outline: 0;
    z-index: 3;
    line-height: 20px;
    transition: all .2s ease-out;
    padding: 0 2rem 1rem;
    position: absolute;
    bottom: 10px;
}

.equipmentCard>a:hover {
    text-decoration: underline;
}

@media (max-width:750px) {
    .equipmentCard {
        height: 670px;
    }
}