.renting-services {
    padding: 5% 10%;
    color: var(--main-color);
    text-align: center;
}

.renting-services .services-heading {
    margin: 0 auto;
}

.renting-services .services-heading.arrow::before {
    left: 15px;
    top: -15px;
}

.renting-services>p {
    width: 60%;
    margin: 0 auto 50px;
    font-size: 20px;
}

@media (max-width:870px) {
    .renting-services {
        padding: 10%;
    }

    .renting-services .services-heading {
        margin: 25px 0;
        padding: 0;
        max-width: 100%;
    }

    .renting-services .services-heading.arrow::before {
        left: -20px;
        top: -5px;
    }

    .renting-cards {
        flex-direction: column;
        gap: 0px;
    }

    .renting-cards .equipment-card {
        margin-bottom: 20px;
        width: 100%;
    }

    .renting>h2 {
        font-size: 32px;
    }

    .renting-services>p {
        width: 100%;
        font-size: 21px;
    }
}