 .servicesNavigator {
     display: flex;
     flex-direction: row;
     justify-content: space-evenly;
 }

 .servicesNavigator div {
     cursor: pointer;
     transition: all .5s ease-in-out;
     padding: 40px 0;
 }

 .servicesNavigator div:hover,
 .servicesNavigator div.active {
     color: var(--orange);
     fill: var(--orange);
 }

 .servicesNavigator .serviceIcon {
     height: 150px;
     width: 100px;
 }

 .servicesNavigator img {
     height: 100px;
     width: 100px;
     border-radius: 50%;
     padding: 3px;
     background-color: transparent;
     transition: all .5s ease-in-out;
 }

 .servicesNavigator div:hover img,
 .servicesNavigator div.active img {
     background-color: var(--orange);
 }

 .servicesNavigator h1 {
     font-size: 20px;
     text-transform: capitalize;
     margin: 25px 0;
 }

 .service {
     display: flex;
     justify-content: flex-start;
     flex-direction: row;
     align-items: center;
 }

 .serviceImage {
     width: 100%;
     height: 100%;
 }

 .service>div {
     width: 50%;
     aspect-ratio: 16/9;
     /* height: 100%; */
 }

 .service>div:last-of-type {
     padding: 0 50px;
 }

 .serviceBody {
     font-size: 20px;
     line-height: 1.3;
     font-weight: 300;
     width: 100%;
 }

 .service>div h2 {
     font-size: 30px;
     font-weight: 900;
     margin: 35px 0;
     color: var(--yellow);
 }

 @media (max-width:720px) {
     .servicesNavigator h1 {
         font-size: 16px;
     }

     .servicesNavigator {
         display: flex;
         flex-wrap: wrap;
         margin: 0;
     }

     .servicesNavigator div {
         margin: 15px 0;
         width: 33.3%;
         padding: 0;
     }

     .servicesNavigator img {
         height: 75px;
         width: 75px;
     }

     .service {
         display: flex;
         flex-direction: column;
         width: 100%;
     }

     .service>div,
     .service>div:last-of-type {
         width: 100%;
         padding: 0;
     }

     .service iframe {
         width: 100%;
         height: 230px;
     }

     .service>div p {
         margin: 0 0 30px;
     }
 }