.productDetails {
    display: flex;
    flex-direction: row;
    padding: 1% 5%;
    justify-content: space-between;
    min-height: 100vh;
    align-items: center;
    gap: 70px;
}

.productDetails .productSection {
    position: relative;
    /* width: 35%; */
}

.productDetails .productSection>h1 {
    margin: 25px;
    color: var(--main-color);
    width: 80%;
}

.productDetails .productSection>div {
    margin: 25px;
    font-size: 20px;
    text-transform: capitalize;
}

.productDetails .productSection>div span {
    font-size: 22px;
    color: #AAA;
    font-weight: 500;
    margin: 0 1px;
}

.productDetails .productSection>div:last-of-type {
    margin-top: 40px;
}

.productDetails .productSection a {
    border-bottom: 1px solid var(--orange);
    background: #fff;
    color: var(--orange);
    padding: 17px 31px;
    font-size: 17px;
    font-weight: 500;
    letter-spacing: .5px;
    text-transform: uppercase;
    transition: all .2s ease-out;
}

.productDetails .productSection a:hover {
    background: var(--orange);
    color: #fff
}

.productBrife {
    display: flex;
    margin-top: 50px;
    color: #FFF;
}

.productBrife>div:first-of-type {
    width: calc(100%/3);
    background-color: #7C1800;
    padding: 3% 2%;
}

.productBrife>div:first-of-type h2 {
    margin: 50px 0;
    font-size: 29px;
    line-height: 36px;
}

.productBrife>div:first-of-type p {
    font-size: 18px;
    letter-spacing: 0.2px;
    line-height: 32px;
}

.productBrife .image {
    width: calc(200%/3);
}

.productBrife .image img {
    width: 100%;
    height: 100%;
}

.renting-product h2 {
    padding: 1%;
    margin: 0 5%;
    font-size: 42px;
    line-height: 53px;
    color: #979FAD;
}

.renting-product h2.arrow::before {
    left: -15px;
    top: -10px;
}

.productApplications {
    display: flex;
    justify-content: flex-start;
}

.appImage {
    width: 60%;
}

.productApplications .applications {
    width: 50%;
    padding: 50px 7%;
}

.productApplications .applications>h2 {
    font-size: 45px;
    color: var(--main-color);
    padding: 0;
    margin: 0;
}

.productApplications .applications>h2::before {
    top: -15px;
    left: -20px;
}

.productApplications .applications ul,
.productBanner div ul {
    list-style-type: none;
    margin: 20px;
}

.productApplications .applications ul li::before,
.productBanner div ul li::before {
    margin-inline-start: -20px;
    content: "";
    width: 5px;
    height: 5px;
    display: block;
    position: absolute;
    top: 13px;
    left: 0px;
    right: 0;
    border: 2px solid var(--orange);
}

.productApplications .applications ul li,
.productBanner div ul li {
    line-height: 1.7;
    position: relative;
    font-size: 22px;
    letter-spacing: 0.2px;
    line-height: 38px;
    color: rgb(45, 58, 77);
}

.productBanner {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: var(--main-color);
    width: 100%;
    padding: 64px;
    color: #FFF;
}

.productBanner div {
    width: 50%;
}

.productBanner div h2 {
    margin: 0;
    color: var(--yellow);
}

.productBanner div ul li {
    color: #FFF;
}

.productBanner h3 {
    font-size: 38px;
    margin: 25px 0;
}

.productBanner p {
    font-size: 18px;
    letter-spacing: 0.2px;
    line-height: 32px;
    margin-top: 20px;
}

.sugesstions {
    padding: 0 5%;
}

@media (max-width:980px) {
    .productDetails {
        flex-direction: column;
        height: auto;
        padding: 0 5%;
        gap: 0;
    }

    .productDetails .product-image {
        width: 100%;
    }

    .productDetails .productSection {
        margin: 50px 0 25px;
        width: 100%;
        text-align: center;
    }

    .productBrife {
        flex-direction: column;
    }

    .productBrife>div:first-of-type {
        width: 100%;
    }

    .productBrife .image {
        width: 100%;
    }

    .productApplications {
        flex-direction: column-reverse;
        width: 100%;
    }

    .productApplications .applications {
        padding: 10%;
        width: 100%;
    }

    .productApplications img {
        height: 100%;
    }

    .appImage {
        width: 100%;
        height: 300px;
    }

    .productBanner {
        flex-direction: column;
        padding: 5%;
    }

    .productBanner div {
        width: 100%;
    }

    .productBanner div ul {
        margin-bottom: 50px;
    }

    .sugesstions {
        padding: 0 10%;
    }
}