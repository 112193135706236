.productImage {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.imagesList {
    display: flex;
    flex-direction: column;
    width: 10%;
}

.imagesList .imageSample {
    width: 55px;
    height: 55px;
    margin: 5px 0;
    cursor: pointer;
    padding: 5px;
    border-radius: 5px;
    border: 2px solid transparent;
}

.imagesList .imageSample.active {
    border: 2px solid var(--orange);
}

.imageContainer {
    width: 600px;
    margin: 6%;
}

.imagePrivew {
    width: 100%;
    max-height: 100%;
}

@media (max-width:600px) {
    .productImage {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        margin: 0 auto;
    }

    .imagesList {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: center;
    }

    .imageContainer {
        width: 100%;
        height: 250px;
        margin: 6% auto;
    }

    .imageContainer>div>img {
        position: relative !important;
        width: 500px !important;
        height: 100px !important;

    }

    .imagePrivew {
        width: 100%;
        height: 100%;
    }
}