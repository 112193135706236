.ourProjectsHeader {
    padding: 50px;
    text-align: center;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    background-color: #EEE;
}

.ourProjectsHeader>h1 {
    font-size: 42px;
    color: var(--main-color);
}

.ourProjectsHeader>span {
    font-size: 22px;
    color: var(--main-color);
    line-height: 1.8;
}