.partners {
    padding: 2%;
    text-align: center;
    align-content: center;
}

.partners>h2 {
    color: var(--orange);
    font-size: var(--main-header-font-size);
}

.partners .logos {
    overflow: hidden;
    padding: 60px 0;
    background: white;
    white-space: nowrap;
    position: relative;
}

.logos:before,
.logos:after {
    position: absolute;
    top: 0;
    width: 250px;
    height: 100%;
    content: "";
    z-index: 2;
}

.logos:before {
    left: 0;
    background: linear-gradient(to left, rgba(255, 255, 255, 0), white);
}

.logos:after {
    right: 0;
    background: linear-gradient(to right, rgba(255, 255, 255, 0), white);
}

.logos:hover .logosSlide {
    animation-play-state: paused;
}

.logosSlide {
    display: inline-block;
    animation: 35s slide infinite linear;
}

.logosSlideAr {
    display: inline-block;
    animation: 35s slideAr infinite linear;
}

.logosSlide img,
.logosSlideAr img {
    margin: 0 30px;
    max-width: 100px;
    height: 100%;
}

.partners p {
    color: #3e3e3e;
    font-size: 22px;
}

@media (max-width:720px) {

    .partners>h2 {
        margin-top: 25px;
    }

    .partners .logos .logosSlide:nth-of-type(2) {
        margin-left: 700px;
    }

    .logosSlide {
        animation: 35s phoneSlide infinite linear;
    }

    .partners .logos .logosSlideAr:nth-of-type(2) {
        margin-right: 620px;
    }

    .logosSlideAr {
        animation: 35s phoneSlideAr infinite linear;
    }
}

@keyframes slide {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(-100%);
    }
}

@keyframes phoneSlide {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(-400%);
    }
}

@keyframes slideAr {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(100%);
    }
}

@keyframes phoneSlideAr {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(400%);
    }
}