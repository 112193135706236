  @import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300;400;500;600;700&family=Noto+Naskh+Arabic:wght@400;500;600;700&display=swap');

  html,
  body {
    overflow-x: hidden;
    font-family: 'Josefin Sans', 'Noto Naskh Arabic', serif;
  }

  :root {
    --main-color: #041A53;
    --yellow: #F6BC1C;
    --orange: #FF3C1F;
    --black: #16151A;
    --main-header-font-size: 40px;
    --main-side-header-font-size: 16px;
  }

  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  .arrow {
    position: relative;
  }

  .arrow::before {
    content: "";
    position: absolute;
    width: 13px;
    height: 13px;
    top: 0;
    left: 0;
    border-top: 7px solid var(--orange);
    border-left: 7px solid var(--orange);
    display: block;
  }

  .services-heading {
    text-align: center;
    max-width: 66.6667%;
    padding: 0 8px;
    margin: 50px 0 50px 16.66667%;
    font-size: 42px;
    line-height: 53px;
    position: relative;
    text-transform: capitalize;
  }

  .background-image {
    background-position: right -75px bottom -75px;
    background-repeat: no-repeat;
    background-size: 250px;
    background-repeat: no-repeat;
    background-blend-mode: screen;
  }

  img {
    object-fit: cover;
  }

  h1,
  h2,
  h3,
  h4,
  h5.h6 {
    text-transform: capitalize;
  }