.testimonialsCard {
    width: 58%;
}

.navigators {
    display: flex;
    justify-content: start;
}

.navigators.ar {
    flex-direction: row-reverse;
}

.navigators .navIcon {
    border: 2.5px solid var(--orange);
    width: 15px;
    height: 15px;
    border-radius: 50%;
    padding: 9px;
    cursor: pointer;
    font-size: 10px;
    margin: 0 15px 15px 0;
    transition: all .5s ease-in-out;
}

.navigators .navIcon:hover {
    background-color: var(--orange);
}

.testimonials>p {
    line-height: 1.5;
    font-size: 18px;
    margin: 22px 0;
}

.client {
    margin: 20px 0;
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
}

.client span {
    color: #b9b9b9;
}

.client>img {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    margin-inline-end: 30px;
    border: 3px solid var(--orange);
}

@media (max-width:800px) {

    .testimonialsCard {
        width: 100%;
    }

    .navigators {
        width: 100%;
        justify-content: center;
    }

    .testimonials>p {
        width: 100%;
    }

    .testimonials,
    .testimonialsSection,
    .statics {
        align-items: center;
        width: 100%;
    }

    .statics div {
        margin: 15px 0;
    }
}