.header {
    height: 100vh;
    width: 100%;
    overflow: hidden;
    background-color: var(--main-color);
}

.header>.headerImg {
    background-size: cover;
    width: 100%;
    z-index: 1;
    animation: changing 10s ease-in-out infinite;
}

@keyframes changing {
    0% {
        opacity: 1;
        transform: scale(1);
    }

    45% {
        opacity: 1;
        transform: scale(1.1);
    }

    55% {
        opacity: 0;
    }

    95% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}