.loading {
    height: 100vh;
    position: relative;
    overflow: hidden;
}

.gear,
.logo {
    position: absolute;
    width: 300px;
    height: 300px;
    top: 50%;
    left: 50%;
    margin: -150px 0 0 -150px;
}

.gear {
    animation: rotating 8s linear infinite;
}

@keyframes rotating {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}