.animation {
    display: flex;
    justify-content: center;
}

.animation .container {
    width: 100%;
}

.animation .reveal {
    position: relative;
    opacity: 0;
}

.animation .reveal.active {
    opacity: 1;
}

.animation .active.fade-bottom {
    animation: fade-bottom 1s ease-in;
}

.animation .active.fade-left {
    animation: fade-left 1s ease-in;
}

.animation .active.fade-right {
    animation: fade-right 1s ease-in;
}

@keyframes fade-bottom {
    0% {
        transform: translateY(50px);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes fade-left {
    0% {
        transform: translateX(-100px);
        opacity: 0;
    }

    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes fade-right {
    0% {
        transform: translateX(100px);
        opacity: 0;
    }

    100% {
        transform: translateX(0);
        opacity: 1;
    }
}