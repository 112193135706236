.contactUsBanner {
    height: 300px;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
}

.contactUsBanner>div {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    justify-content: center;
}

.contactUsBanner h2 {
    width: 65%;
    margin: 0 auto 50px;
    padding: 25px;
    color: #142A39;
    font-size: 30px;
    font-weight: 600;
    text-transform: capitalize;
}

.contactUsBtn {
    position: relative;
    display: inline-block;
    cursor: pointer;
    outline: none;
    border: 0;
    vertical-align: middle;
    text-decoration: none;
    background: transparent;
    padding: 0;
    font-size: inherit;
    font-family: inherit;
    width: 12rem;
    height: auto;
}

.contactUsBtn .circle {
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    position: relative;
    display: block;
    margin: 0;
    width: 3rem;
    height: 3rem;
    background: var(--orange);
    border-radius: 1.625rem;
}

.contactUsBtn.ar .circle {
    margin-inline-start: -20px;
}

.contactUsBtn .circle .icon {
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    background: #fff;
}

.contactUsBtn .circle .icon.lightArrow {
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    left: 0.625rem;
    width: 1.125rem;
    height: 0.125rem;
    background: none;
}

.contactUsBtn.ar .circle .icon.lightArrow {
    right: .6rem;
    width: .8rem;
}

.contactUsBtn .circle .icon.lightArrow::before {
    position: absolute;
    content: '';
    top: -0.30rem;
    right: 0.0625rem;
    width: 0.625rem;
    height: 0.625rem;
    border-top: 0.125rem solid #fff;
    border-right: 0.125rem solid #fff;
    transform: rotate(45deg);
}

.contactUsBtn.ar .circle .icon.lightArrow::before {
    transform: rotate(225deg);
}

.contactUsBtn .buttonText {
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0.75rem 0;
    margin: 0 0 0 1.85rem;
    color: var(--orange);
    font-weight: 700;
    line-height: 1.6;
    text-align: center;
    text-transform: uppercase;
}

.contactUsBtn:hover .circle {
    width: 100%;
}

.contactUsBtn:hover .circle .icon.lightArrow {
    background: #fff;
    transform: translate(1rem, 0);
}

.contactUsBtn.ar:hover .circle .icon.lightArrow {
    transform: translate(3rem, 0);
}

.contactUsBtn:hover .buttonText {
    color: #fff;
}

@media (max-width:750px) {
    .contactUsBanner h2 {
        width: 100%;
    }
}