.banner {
    padding: 5%;
    text-align: center;
    background-color: #001F3F;
    color: #fff;
}

.equipmentDetails {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.equipmentDetails p {
    margin-top: 25px;
    font-size: 20px;
    color: #CCC;
    width: 60%;
}

.equipmentDetails a {
    position: relative;
    cursor: pointer;
    display: inline-block;
    width: fit-content;
    transition: all 0.3s;
    color: var(--yellow);
    font-weight: 900;
    text-align: center;
    font-size: 1.5rem;
    line-height: 1.8;
    margin: 25px 0;
}

.equipmentDetails a:after {
    content: '';
    position: absolute;
    height: 1.5px;
    width: 100%;
    background: currentColor;
    bottom: -6px;
    opacity: 0.2;
    left: 0;
}

.equipmentDetails a:before {
    content: '';
    position: absolute;
    height: 1.5px;
    width: 0;
    transition: all 0.4s ease-in-out;
    background: currentColor;
    bottom: -6px;
    left: auto;
    right: 0;
}

.equipmentDetails a:hover:before {
    width: 100%;
    right: auto;
    left: 0;
}

.container {
    display: grid;
    grid-template-columns: auto auto auto;
    grid-gap: 3rem;
    padding: 2rem 4rem 4rem;
}

.container .wrapper {
    transform: skew(-10deg);
    transition: 0.25s;
}

.container .wrapper:before {
    content: '';
    left: 0;
    top: 0;
    height: calc(100% - 10px);
    width: calc(100% - 10px);
    border-radius: 40px;
    opacity: 0;
    border: 2px solid #ece9e6;
    position: absolute;
    z-index: -1;
    transition: 0.5s 0.05s;
}

.container .wrapper:hover:before {
    opacity: 1;
    transform: translateY(1.5rem) translateX(1.5rem);
}

.container .wrapper:nth-child(1) .card:after,
.container .wrapper:nth-child(2) .card:after,
.container .wrapper:nth-child(3) .card:after,
.container .wrapper:nth-child(4) .card:after,
.container .wrapper:nth-child(5) .card:after,
.container .wrapper:nth-child(6) .card:after {
    content: '';
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-size: cover;
    background-blend-mode: screen;
    transform: skew(10deg) scale(1.2);
    transition: 0.25s;
}

.container .wrapper:nth-child(1) .card:after {
    background-image: url("/public/Assets/Images/equipment/jetpump-9.webp");
}

.container .wrapper:nth-child(2) .card:after {
    background-image: url("/public/Assets/Images/ourServices/waterBlasting-tackling.webp");
}

.container .wrapper:nth-child(3) .card:after {
    background-image: url("/public/Assets/Images/equipment/ptc4-4.webp");
}

.container .wrapper:nth-child(4) .card:after {
    background-image: url("/public/Assets/Images/ourServices/heatexchanger-12.webp");
}

.container .wrapper:nth-child(5) .card:after {
    background-image: url("/public/Assets/Images/equipment/jetpump-10.webp");
}

.container .wrapper:nth-child(6) .card:after {
    background-image: url("/public/Assets/Images/ourServices/paintstripping-1.webp");
}

.container .wrapper:hover {
    transform: translateX(-1rem) translateY(-1rem) skew(-10deg);
}

.container .wrapper:hover .card {
    box-shadow: 0.5rem 0.5rem 1rem rgba(0, 0, 0, 0.1);
}

.container .wrapper:hover .card:after {
    background-blend-mode: normal;
}

.container .wrapper:hover .card .fal {
    bottom: 4rem;
    background-color: rgba(255, 255, 255, 0.8);
}

.container .card {
    background-color: #fff;
    border-radius: 40px;
    height: 15rem;
    padding: 2rem;
    position: relative;
    overflow: hidden;
}

.container .card:after {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.container .card .fal {
    position: absolute;
    bottom: -1rem;
    right: 3rem;
    border-radius: 100%;
    padding: 1rem;
    font-size: 1.5rem;
    color: #373f51;
    z-index: 1;
    transform: skew(10deg) translateY(50%);
    text-align: center;
    transition: 0.25s;
}

@media (max-width:900px) {
    .equipmentDetails p {
        width: 100%;
    }

    .container {
        grid-template-columns: auto;
    }

}