.mainServices {
    text-align: center;
    padding: 5%;
    background-color: #16151A;
    color: #FFF;
    overflow: hidden;
}

.mainServices>h1 {
    margin-bottom: 10px;
    text-transform: capitalize;
    font-size: var(--main-header-font-size);
}

.mainServices>hr {
    margin: 0 auto 20px;
    width: 3%;
    border-style: dotted none none;
    border-width: 5px;
    border-color: #aaa;
}

@media (max-width:720px) {
    /* .mainServices {
        min-height: 1200px;
    } */

    .mainServices .servicesNavigator h1 {
        font-size: 12px;
    }
}