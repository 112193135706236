.whoWeAre .header {
    height: 10px;
}

.aboutCompany {
    display: flex;
    flex-direction: row;
    padding: 5%;
    justify-content: space-between;
}

.aboutCompany>div {
    width: 50%;
}

.aboutCompany span {
    color: var(--yellow);
    font-size: 25px;
}

.aboutCompany h1 {
    font-size: 42px;
    color: var(--main-color);
    margin: 25px 0;
}

.aboutCompany hr {
    width: 25%;
    border: 1.5px solid var(--yellow);
    margin: 25px 0;
}

.aboutCompany p {
    line-height: 1.5;
    color: rgb(125, 125, 125);
    font-size: 30px;
    width: 70%;
}

.aboutCompany p:last-of-type {
    width: 100%;
    font-size: 20px;
}

.whoWeAreGrid {
    display: grid;
    grid-template-columns: repeat(3, calc(100%/3));
    overflow: hidden;
}

.gridBox {
    color: var(--main-color);
    min-height: 25vh;
    font-size: 29px;
    text-transform: capitalize;
    line-height: 36px;
    width: 100%;
    padding: 7.336875rem 2rem 1.975625rem;
    position: relative;
}

.gridBox:nth-child(n) {
    background-color: #052159;
}

.gridBox:nth-child(2n) {
    background-color: #7c1800;
}

.gridBox:nth-child(5n) {
    background-color: #008170;
}

.gridBox.statistics span {
    padding-bottom: 1.5rem;
    font-size: 90px;
    letter-spacing: 2px;
    line-height: 72px;
    color: var(--orange);
    margin-bottom: 25px;
}

.gridBox p {
    font-size: 29px;
    font-weight: 300;
    letter-spacing: 1.26px;
    line-height: 36px;
}

.gridBox.statistics {
    background-color: whitesmoke;
}

.gridBox.statistics:first-of-type {
    background-color: #C1D0B5;
}

.gridBox.statistics:nth-of-type(2) {
    background-color: #D6E8DB;
}

.gridBox.companyValues {
    color: var(--main-color);
    grid-column-start: 1;
    grid-column-end: 3;
    background-color: aliceblue;
    padding: 10%;
}

.gridBox.companyValues>h2 {
    font-size: 42px;
    line-height: 53px;
    font-weight: 500;
}

.gridBox.companyValues>h2::before,
.gridBox.culture>h2::before,
.gridBox.experiance>h2::before {
    top: -17px;
    left: -17px;
}

.gridBox.m3>h2::before {
    top: -20px;
    left: -20px;
}

.gridBox.companyValues>p {
    font-size: 20px;
    letter-spacing: .2px;
    line-height: 32px;
    width: 80%;
}

.gridBox.value>span {
    font-size: 29px;
    line-height: 36px;
    color: var(--yellow);
    font-weight: 900;
}

.gridBox.value>p {
    font-style: normal;
    color: #fff;
    font-weight: normal;
    font-size: 18px;
    letter-spacing: .2px;
    line-height: 32px;
    margin-bottom: 16px;
    width: 80%;
}

.gridBox.culture {
    background-color: transparent;
    height: 100%;
    padding-top: 85px;
    font-size: 25px;
}

.gridBox.culture>h2 {
    width: 80%;
    line-height: 1.3;
    font-weight: 500;
}

.gridBox.culture>p {
    font-size: 18px;
    letter-spacing: .2px;
    line-height: 32px;
    width: 80%;
}

.cultureImage {
    grid-column-start: 2;
    grid-column-end: 4;
    padding: 0;
    height: 70vh;
}

.cultureImage>img,
.gridBox.image>img {
    width: 100%;
    height: 100%;
}

.gridBox.image {
    grid-column-start: 1;
    grid-column-end: 3;
    padding: 0;
    height: 70vh;
}

.gridBox.companyMassage {
    padding: 6% 2rem;
}

.gridBox.companyMassage>p {
    font-style: normal;
    color: #fff;
    font-weight: normal;
    font-size: 18px;
    letter-spacing: .2px;
    line-height: 32px;
    width: 80%;
}

.gridBox.experiance {
    transition: all .2s ease-in-out;
}

.gridBox.experiance:hover {
    box-shadow: inset 11px 11px 20px 0px #000000c2;
}

.gridBox.experiance>h2 {
    font-size: 25px;
    color: var(--orange);
    width: 70%;
}

.gridBox.experiance>h2::before {
    border-top: 4px solid #FFF;
    border-left: 4px solid #FFF;
}

.btn {
    padding: 5px 10px;
    border: none;
    border-bottom: 2px solid var(--orange);
    color: var(--orange);
    background-color: #FFF;
    outline: none;
    border-radius: 5px;
    font-size: 18px;
    cursor: pointer;
    transition: all .5s ease-in-out;
}

.btn:hover {
    color: #FFF;
    background-color: var(--orange);
}

.m3 {
    grid-column-start: 1;
    grid-column-end: 4;
    padding: 3% 10% 0;
}

.gridBox.m1 {
    padding: 0;
    height: 400px;
}

.gridBox.m2 {
    grid-column-start: 2;
    grid-column-end: 4;
    font-size: 20px;
}

.gridBox.m2>span {
    position: relative;
    padding-inline-start: 18px;
    color: #aaa;
}

.gridBox.m2>span::before {
    content: "";
    position: absolute;
    left: -14px;
    top: 9px;
    background: #c00;
    width: 20px;
    height: 3px;
}

.gridBox.ar.m2>span::before {
    right: 50px;
    top: 15px;
}

.backgroundImage {
    background-repeat: no-repeat;
    background-repeat: no-repeat;
    background-blend-mode: screen;
}

@media (max-width:1080px) {
    .aboutCompany {
        flex-direction: column;
    }

    .aboutCompany>div {
        text-align: center;
        width: 100%;
    }

    .aboutCompany hr {
        margin: 0 auto 25px;
    }

    .aboutCompany>div:first-of-type p {
        font-size: 40px;
        color: #40454e;
    }

    .whoWeAreGrid {
        display: flex;
        flex-direction: column;
    }

    .gridBox.companyValues>p {
        margin: 15px 0;
        width: 100%;
    }

    .gridBox.value {
        min-height: 20vh;
        padding: 2rem;
    }

    .gridBox.value>p {
        width: 100%;
    }

    .gridBox.culture {
        width: 100%;
        height: auto;
    }

    .gridBox.culture>h2 {
        text-align: center;
        width: 90%;
    }

    .gridBox.culture>p {
        width: 88%;
    }

    .gridBox.experiance>h2 {
        font-size: 25px;
        color: #FFF;
        width: 95%;
    }

    .gridBox.companyMassage>p {
        width: 100%;
    }

    .gridBox.m3 {
        background-color: #FFF;
        padding: 3%;
        text-align: center;
    }

    .gridBox.m3>h2 {
        font-weight: 500;
        width: 95%;
    }

    .gridBox.m3>h2::before {
        left: 0;
    }

    .gridBox.m3>p {
        width: 95%;
    }
}